import { store } from '@store/index';
import { UserActions } from '@store/slices/user';
import { SettingsActions } from '@store/slices/settings';
import { clearAllStates } from '@components/DataGrid/utils/cache';
import AuthService from '@api/AuthService';

export default async function logoutHandler() {
  // Clear tokens
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');

  // Dispatch Redux actions
  store.dispatch(UserActions.logout());
  store.dispatch(SettingsActions.setFacility(null));
  store.dispatch(SettingsActions.setOrganization(null));
  store.dispatch(SettingsActions.setSelectedYear(''));

  // Clear cache
  await clearAllStates();

  // Redirect to the logout route
  window.location.replace(AuthService.links.logout());
}