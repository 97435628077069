import { UserType } from '@declarations/common/enums/UserType';
import initInstance from './axios';
import { ListWithPagination, PaginationParams } from '@declarations/common/pagination';
import { User } from '@declarations/models/User';
import { FetchListParams } from '@declarations/common/fetchListParams';
import { ExpertRole } from '@declarations/common/enums/ExpertRole';
import logoutHandler from '@utils/logoutHandler';
import axios, { AxiosResponse } from 'axios';

const api = initInstance('/user');

const paths = {
  upsert: '/',
  checkRegisterToken: '/check-reset-token',
  resetPassword: '/reset-password',
  forgotPassword: '/forgot-password',
  getById: (id: number) => `/${id}`,
  list: '/list',
  disable: '/disable',
  me: '/me',
  delete: (id: number) => `/${id}`,
  listCSV: '/list/csv',
};

type GetMeResponse = {
  id: number;
  firstname: string;
  lastname: string;
  position: string;
  organizations: { id: number; name: string }[];
  facilities: { id: number; name: string; organizationId: number; }[];
  email: string;
  contactNumber: string;
  type: UserType;
  expertRole?: ExpertRole;
};

const meRequestAbortController = new AbortController();

const UserService = {
  upsert: (user: User | FormData) => api.post(paths.upsert, {...user, expertUser: undefined}),
  // ?????
  register: (token: string) => api.post(paths.checkRegisterToken, {token}),
  resetPassword: (token: string, password: string, confirmPassword: string) => api.post(paths.resetPassword, {
    token,
    password,
    ConfirmPassword: confirmPassword,
  }),
  forgotPassword: (email: string) => api.post(paths.forgotPassword, JSON.stringify(email)),
  getById: (id: number) => api.get<User>(paths.getById(id))
    .then(res => ({...res, data: {...res.data, expertUser: !!res.data.expertRole}})),
  list: (params: PaginationParams) => api.post<ListWithPagination<User>>(paths.list, params),
  disable: (id: number) => api.post(paths.disable, {userId: id}),
  me: (): Promise<AxiosResponse<GetMeResponse>> => api.get<GetMeResponse>(paths.me, {signal: meRequestAbortController.signal}).catch(async error => {
    if (error.response && error.response.status === 404) {
      meRequestAbortController.abort();
      alert('The user is not found in the system. Please check the email address you used and try to log in again.');
      await logoutHandler();
    }
    return Promise.reject(error);
  }),
  delete: (id: number) => api.delete(paths.delete(id)),
  exportCSV: async (params: Omit<FetchListParams, 'pagination'>) => api.post<File>(paths.listCSV, params),
};

export default UserService;
