import React, { useCallback, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from '@components/PrivateRoute';
import routes from '../constants/routes';

import PageWithHeader from '../components/page/PageWithHeader';

import Page404 from './Page404';

import Login from './Login';
import Thanks from './auth/Thanks';
// import Login from './auth/Login';
import NewPass from './auth/NewPass';
import Forgot from './auth/Forgot';
// import LoginPage from './auth/LoginPage';

import DashboardPage from './Dashboard';

import IncidentReports from './IncidentReports';
import IncidentForm from './IncidentForm';

import LawsuitForm from './LawsuitForm';
import LawsuitReports from './LawsuitReports';

import MedicalRRRecords from './MedicalRRRecords';
import MedicalRRForm from './MedicalRRForm';

import Facilities from './Facilities';
import FacilityForm from './FacilityForm';

import UserManagementPage from './userManagment/UserManagementPage';
import UserProfilePage from './profile/UserProfilePage';
import UserManagementForm from './UserManagementForm';

import OrganizationsPage from './organizations/OrganizationsPage';
import OrganizationForm from './OrganizationForm';
import { registerChartJSDependencies } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions, UserSelectors } from '@store/slices/user';
import { UserType } from '@declarations/common/enums/UserType';
import { SettingsActions, SettingsSelectors } from '@store/slices/settings';
import UserService from '@api/userService';
import { CircularProgress } from '@mui/material';
import IncidentProviderTab from './IncidentForm/IncidentProviderTab';
import MedicalRRProviderTab from './MedicalRRForm/MedicalRRProviderTab';
import LawsuitProviderTab from './LawsuitForm/LawsuitProviderTab';
import IncidentClinicalReviewTab from './IncidentForm/IncidentClinicalReviewTab';
import MedicalRRClinicalReviewTab from './MedicalRRForm/MedicalRRClinicalReviewTab';
import LawsuitClinicalReviewTab from './LawsuitForm/LawsuitClinicalReviewTab';
import IncidentLegalReviewTab from './IncidentForm/IncidentLegalReviewTab';
import MedicalRRLegalReviewTab from './MedicalRRForm/MedicalRRLegalReviewTab';
import LawsuitLegalReviewTab from './LawsuitForm/LawsuitLegalReviewTab';
import { SessionActions } from '@store/slices/session';
import { DashboardMode } from '@declarations/common/enums/DashboardMode';

function PrivateRoutes() {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(UserSelectors.isAuthorized);
  const user = useSelector(UserSelectors.user);
  const selectedFacilityId = useSelector(SettingsSelectors.selectedFacility)?.id;

  registerChartJSDependencies();

  const fetchMe = useCallback(async () => {
    const { data: me } = await UserService.me();

    dispatch(UserActions.setUser(me));
    
    if (me.type === UserType.FacilityUser) {
      dispatch(SettingsActions.setFacility(me.facilities[0]))
    } else if (!me.facilities?.find(f => f.id === selectedFacilityId)) {
      dispatch(SettingsActions.setFacility(null))
    }

    if (me.type === UserType.External) {
      dispatch(SessionActions.setDashboardMode(DashboardMode.Expert));
    } else if (!me.expertRole) {
      dispatch(SessionActions.setDashboardMode(DashboardMode.LTC));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isAuthorized) {
      fetchMe();
    }
  }, [isAuthorized, dispatch, fetchMe]);

  if (!user?.id && isAuthorized) {
    return (
      <div style={{display: 'flex', width: '100%', height: '100vh', justifyContent: 'center', alignItems: 'center', gap: '16px'}}>
        <CircularProgress />
        <div>Preparing Dashboard...</div>
      </div>
    );
  }

  return (
    <Routes>
      <Route
        element={(
          <PageWithHeader>
            <PrivateRoute />
          </PageWithHeader>
        )}
      >
        <Route path={routes.dashboard} element={<DashboardPage />} />

        <Route path={routes.incidents} element={<IncidentReports />} />
        <Route path={routes.medicalRecordRequests} element={<MedicalRRRecords />} />
        <Route path={routes.lawsuits} element={<LawsuitReports />} />

        <Route path={routes.organizations} element={<OrganizationsPage />} />
        <Route path={routes.facilities} element={<Facilities />} />
        <Route path={routes.userManagement} element={<UserManagementPage />} />

        <Route path={routes.userProfile} element={<UserProfilePage />} />

        <Route path={routes.incidentReportProviderTab} element={<IncidentProviderTab />} />
        <Route path={routes.incidentReportClinicalTab} element={<IncidentClinicalReviewTab />} />
        <Route path={routes.incidentReportLegalTab} element={<IncidentLegalReviewTab />} />
        <Route path={routes.incidentReportForm} element={<IncidentForm />} />
        <Route path={routes.medicalRecordRequestProviderTab} element={<MedicalRRProviderTab />} />
        <Route path={routes.medicalRecordRequestClinicalTab} element={<MedicalRRClinicalReviewTab />} />
        <Route path={routes.medicalRecordRequestLegalTab} element={<MedicalRRLegalReviewTab />} />
        <Route path={routes.medicalRecordRequestForm} element={<MedicalRRForm />} />
        <Route path={routes.lawsuitProviderTab} element={<LawsuitProviderTab />} />
        <Route path={routes.lawsuitClinicalTab} element={<LawsuitClinicalReviewTab />} />
        <Route path={routes.lawsuitLegalTab} element={<LawsuitLegalReviewTab />} />
        <Route path={routes.lawsuitForm} element={<LawsuitForm />} />
        <Route path={routes.userForm} element={<UserManagementForm />} />
        <Route path={routes.organizationForm} element={<OrganizationForm />} />
        <Route path={routes.facilityForm} element={<FacilityForm />} />

        <Route path="*" element={<Navigate to={routes.notFound} replace />} />
      </Route>
    </Routes>
  );
}

export default function App() {
  return (
    <Routes>
      <Route path={routes.loginPage} element={<Login />} />
      {/* <Route path={routes.login} element={<Login />} /> */}
      <Route path={routes.thanks} element={<Thanks />} />
      <Route path={routes.forgot} element={<Forgot />} />
      <Route path={routes.recovery} element={<NewPass />} />
      <Route path={routes.notFound} element={<Page404 />} />
      <Route path="*" element={<PrivateRoutes />} />
    </Routes>
  );
}
